/* eslint-disable */
import { assetImages } from "constants";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStateManager } from "react-select";

/*import url and gateway methods */
import { getData } from "utils/Gateway";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const LeftMenuPanelPopup = ({ moduleName, userInfo }) => {
  const moduleAccess = localStorage.getItem("moduleaccess");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  return (
    <div
      className="offcanvas offcanvas-start bg-white border-0"
      data-bs-scroll="true"
      data-bs-backdrop="true"
      tabIndex="-1"
      id="offcanvasMainNav"
      aria-labelledby="offcanvasMainNavLabel"
    >
      <div className="offcanvas-header flex-column align-items-start body-bg p-4">
        {/* ------- menu arrow button start ------ */}
        <button
          type="button"
          className="btn-close p-0 bg-white rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <span className="d-block material-symbols-outlined icon-lg">
            arrow_back
          </span>
        </button>
        {/* ------- menu arrow button end ------ */}

        {/* ------- user info section start ------ */}
        <div className="user d-flex align-items-center gap-3 mt-4">
          <div className="avatar rounded-circle overflow-hidden">
            <img
              src={
                userInfo?.photoimage
                  ? url.SERVER_URL + userInfo?.photoimage?.path
                  : assetImages?.defaultUser
              }
              alt="UserImage"
              className="w-100 h-100 object-fit-cover object-center"
            />
          </div>
          <div className="name">
            <p className="fs-md fw-semibold mb-0">
              {userInfo.name} {userInfo.surname}
            </p>
            <p className="fs-xs lh-1 text-gray">{userInfo.position}</p>
          </div>
        </div>
        {/* ------- user info section end ------ */}
      </div>

      {/* ------- module list section start ------ */}
      <div className="offcanvas-body px-3 py-4">
        <ul className="nav flex-column gap-2 fs-md lh-1 fw-semibold">
          {/* ------ dashboard link start ----- */}
          <li className="nav-item">
            <Link
              to="/admin/dashboard"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "dashboard" ? "active" : ""
              } `}
              aria-current="page"
            >
              <span className="d-block material-symbols-outlined">
                grid_view
              </span>
              <span>{t("Dashboard")}</span>
            </Link>
          </li>
          {/* ------ dashboard link end ----- */}

          {/* ------ administration link start ----- */}

          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <li className="nav-item">
              <Link
                to="/admin/administration"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "administration" ? "active" : ""
                } `}
                aria-current="page"
              >
                <span className="d-block material-symbols-outlined">
                  admin_panel_settings
                </span>
                <span>{t("Administration")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* ------ administration link end ----- */}

          {/* <!-- ========== Start user direct access Section ========== --> */}
          <li className="nav-item">
            <Link
              to="/admin/directaccess"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "directaccess" ? "active" : ""
              } `}
              aria-current="page"
            >
              <span className="d-block material-symbols-outlined">
                switch_access_2
              </span>
              <span>{t("Direct Access")}</span>
            </Link>
          </li>
          {/* <!-- ========== End user direct access Section ========== --> */}

          {/* ------ app users link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_USER") ? (
            <li className="nav-item">
              <Link
                to="/admin/appuser/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "appuser" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  person
                </span>
                <span>{t("App Users")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* ------ app users link end ----- */}

          {/* ------ crm link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_CRM") ? (
            <li className="nav-item">
              <Link
                to="/admin/crm/lead/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "crm" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  space_dashboard
                </span>
                <span>{t("CRM")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* ------ crm link end ----- */}

          {/* ------ success stories link start ----- */}
          {/* <li className="nav-item">
            <Link
              to="/admin/successstories/list"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "successstories" ? "active" : ""
              } `}
            >
              <span className="d-block material-symbols-outlined">
                local_library
              </span>
              <span>Success Stories</span>
            </Link>
          </li> */}
          {/* ------ success stories link end ----- */}

          {/* ------ companies link start ----- */}

          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_COMPANY") ? (
            <li className="nav-item">
              <Link
                to="/admin/companies"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "companies" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  meeting_room
                </span>
                <span>{t("Companies")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* ------ companies link end ----- */}

          {/* ------ events link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_EVENT") ? (
            <li className="nav-item">
              <Link
                to="/admin/events/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "events" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  calendar_today
                </span>
                <span>{t("Events")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* ------ events link end ----- */}

          {/* ------ groups link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_GROUP") ? (
            <li className="nav-item">
              <Link
                to="/admin/group/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "group" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  diversity_2
                </span>
                <span>{t("Groups")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* ------ groups link end ----- */}

          {/* ------ survey link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_SURVEY") ? (
            <li className="nav-item">
              <Link
                to="/admin/survey/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "survey" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  live_help
                </span>
                <span>{t("Survey")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* ------ survey link end ----- */}

          {/* -------- project management start ---------- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_PROJECT") ? (
            <li className="nav-item">
              <Link
                to="/admin/projectmanagement/dashboard"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "projects" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  contract
                </span>
                <span>{t("Project Management")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* -------- project management end ---------- */}

          {/* my task start */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_TASK") ? (
            <li className="nav-item">
              <Link
                to="/admin/mytasklist"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "mytask" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">task</span>
                <span>{t("Task Management")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* my task end */}

          {/* all log start */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_LOGS") ? (
            <li className="nav-item">
              <Link
                to="/admin/alllogs/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "alllogs" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">timer</span>
                <span>{t("Task Logs")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* all log end */}

          {/* my invoices */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_INVOICE") ? (
            <li className="nav-item">
              <Link
                to="/admin/invoice/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "invoice" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  point_of_sale
                </span>
                <span>{t("Invoices")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}

          {/* my invoice end */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <li className="nav-item">
              <Link
                to="/admin/invoicedoc/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "invoicedoc" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  point_of_sale
                </span>
                <span>{t("Invoice Docs")}</span>
              </Link>
            </li>
          ) : null}

          {/* my payments */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_PAYMENT") ? (
            <li className="nav-item">
              <Link
                to="/admin/payment/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "payment" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">euro</span>
                <span>{t("Payments")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* my invoice end */}

          {/* my invoices */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_SUBSCRIPTION") ? (
            <li className="nav-item">
              <Link
                to="/admin/subscription/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "subscription" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  card_membership
                </span>
                <span>{t("Subscription")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* my invoice end */}

          {/* ------ challenges link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_CHALLENGE") ? (
            <li className="nav-item">
              <Link
                to="/admin/challenges/dashboard"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "challenges" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">work</span>
                <span>{t("Challenges")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* ------ challenges link end ----- */}

          {/* ------ courses link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_COURSE") ? (
            <li className="nav-item">
              <Link
                to="/admin/courses"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "courses" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  local_library
                </span>
                <span>{t("Courses")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* ------ courses link end ----- */}

          {/* ------ marketplace link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_MARKETPLACE") ? (
            <li className="nav-item">
              <Link
                to="/admin/marketplace/products/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "marketplace" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  storefront
                </span>
                <span>{t("Marketplace")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* ------ marketplace link end ----- */}

          {/* ------ process link start ----- */}
          {/* <li className="nav-item">
            <Link
              to="/admin/process"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "process" ? "active" : ""
              } `}
            >
              <span className="d-block material-symbols-outlined">
                published_with_changes
              </span>
              <span>Process</span>
            </Link>
          </li> */}
          {/* ------ process link end ----- */}

          {/* ------ my money link start ----- */}
          {/* <li className="nav-item">
            <Link
              to="#"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "myMoney" ? "active" : ""
              } `}
            >
              <span className="d-block material-symbols-outlined">
                account_balance_wallet
              </span>
              <span>My Money</span>
            </Link>
          </li> */}
          {/* ------ my money link end ----- */}

          {/* ------ coupons link start ----- */}
          {/* <li className="nav-item">
            <Link
              to="/admin/coupons/list"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "coupons" ? "active" : ""
              } `}
            >
              <span className="d-block material-symbols-outlined">sell</span>
              <span>Coupons</span>
            </Link>
          </li> */}
          {/* ------ coupons link end ----- */}

          {/* ------ offers link start ----- */}
          {/* <li className="nav-item">
            <Link
              to="/admin/offers/list"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "offers" ? "active" : ""
              } `}
            >
              <span className="d-block material-symbols-outlined">
                heap_snapshot_multiple
              </span>
              <span>Offers</span>
            </Link>
          </li> */}
          {/* ------ offers link end ----- */}

          {/* ---------- affiliations link start ------------ */}
          {/* <li className="nav-item">
            <Link
              to="/admin/affiliate-proposals"
              className={`d-flex align-items-center gap-3 text-black ${
                moduleName == "affiliation" ? "active" : ""
              } `}
            >
              <span className="d-block material-symbols-outlined">
                person_pin
              </span>
              <span>Affiliation</span>
            </Link>
          </li> */}
          {/* ---------- affiliations link end ------------ */}

          {/* ------ blog link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_TICKET") ? (
            <li className="nav-item">
              <Link
                to="/admin/tickets/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "tickets" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  transit_ticket
                </span>
                <span>Tickets</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* ------ blog link end ----- */}

          {/* ------ blog link start ----- */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          moduleAccess.includes("MOD_CMS") ? (
            <li className="nav-item">
              <Link
                to="/admin/blogs/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "blogs" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">
                  article
                </span>
                <span>{t("Blog")}</span>
              </Link>
            </li>
          ) : (
            ""
          )}
          {/* ------ blog link end ----- */}

          {/* faq start  */}
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <li className="nav-item">
              <Link
                to="/admin/faq/list"
                className={`d-flex align-items-center gap-3 text-black ${
                  moduleName == "faq" ? "active" : ""
                } `}
              >
                <span className="d-block material-symbols-outlined">quiz</span>
                <span>FAQ</span>
              </Link>
            </li>
          ) : null}
          {/* faq end  */}
        </ul>
      </div>
      {/* ------- module list section end ------ */}
    </div>
  );
};

export default LeftMenuPanelPopup;
