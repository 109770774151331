/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

const AssignExpertPopup = ({
  selectedFaqId = null,
  setSelectedFaqId = () => {},
  afterPopupClose = () => {},
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [expertLoading, setExpertLoading] = useState(false);
  const [expertList, setExpertList] = useState([]);
  const [faqExperts, setFaqExperts] = useState([]);

  const [expertName, setExpertName] = useState("");

  const [checkedExperts, setCheckedExperts] = useState({}); // To store checked state

  const [isSaving, setisSaving] = useState(false);

  const [validation, setValidation] = useState({
    expertError: false,
  });

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (faqExperts.length === 0) {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get expert list
  const getAllExpertList = async (isLoading = false, expertString = "") => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_APP_USERS + `?token=${token}&limit=50`;

      if (isLoading) {
        setExpertLoading(true);
      }

      if (expertString !== "") {
        requestURL += `&userstring=${expertString}`;
      }

      const response = await getData(requestURL);

      setExpertLoading(false);

      console.log(response);

      if (response.status) {
        setExpertList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for check expert
  const expertCheckHandler = (expertId, isChecked) => {
    setCheckedExperts((prevState) => ({
      ...prevState,
      [expertId]: isChecked, // Update checked state for the specific expert
    }));

    if (isChecked) {
      // Add to faqExperts if checked, if not already there
      if (!faqExperts.includes(expertId)) {
        setFaqExperts((prevState) => [...prevState, expertId]);
      }
    } else {
      // Remove from faqExperts if unchecked
      setFaqExperts((prevState) => prevState.filter((id) => id !== expertId));
    }
  };

  //get faq details
  const getFaqDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData(requestURL);

      if (response.status) {
        const data = response.data;

        if (data.experts && data.experts.length > 0) {
          for (let expertId of data.experts) {
            expertCheckHandler(expertId, true);
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save faq function
  const saveFaqHandler = async (e) => {
    if (validationHandler()) {
      try {
        setisSaving(true);

        let apiData = {
          experts: faqExperts,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (selectedFaqId) {
          requestURL += url.API_UPDATE_FAQ + `/${selectedFaqId}?token=${token}`;

          response = await putData(requestURL, apiData);
        }

        setisSaving(false);

        // console.log(response);

        if (response.status) {
          // close the modal
          let offCanvasPopup = document.querySelector("#offcanvasAssignExpert");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();

          resetHandler();
          afterPopupClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const resetHandler = () => {
    setExpertList([]);
    setFaqExperts([]);
    setExpertName("");
    setCheckedExperts({});
    setSelectedFaqId(null);

    setValidation({
      expertError: false,
    });
  };

  useEffect(() => {
    if (selectedFaqId) {
      getAllExpertList(true);
      getFaqDetails();
    }
  }, [selectedFaqId]);

  useEffect(() => {
    if (expertName !== "" && expertName.length > 0) {
      const filterSearch = expertList.filter((expert) =>
        expert.username.toLowerCase().includes(expertName.toLowerCase())
      );

      if (filterSearch.length === 0) {
        getAllExpertList(false, expertName);
      }
    } else {
      const timeoutId = setTimeout(() => {
        getAllExpertList(false);
      }, 500); // Debounce the input for better UX
      return () => clearTimeout(timeoutId);
    }
  }, [expertName]);

  // Filtered list based on search input if length > 2
  const filteredExperts =
    expertName.length > 2
      ? expertList.filter((expert) =>
          expert.username.toLowerCase().includes(expertName.toLowerCase())
        )
      : expertList;

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasAssignExpert"
      aria-labelledby="offcanvasAssignExpertLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasAssignExpertLabel">
          Assign Experts
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="field-container flex-fill">
            <div className="search position-relative mb-4">
              <button className="d-block position-absolute top-50 border-0 text-primary material-symbols-outlined bg-transparent ms-3 translate-middle-y">
                search
              </button>
              <input
                type="text"
                className="form-control bg-gray-100 rounded-0 shadow-none ps-5"
                placeholder="Search expert by name"
                id="expertSearch"
                value={expertName}
                onChange={(e) => setExpertName(e.target.value)}
              />
              {validation.expertError && (
                <div className="text-danger mt-2">
                  Please select at least one expert.
                </div>
              )}
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                Experts
              </label>
              <ul
                className="experts"
                id="appexperts"
                style={{ maxHeight: "400px", overflowY: "auto" }}
              >
                {expertLoading
                  ? [1, 2, 3].map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="px-3 py-3 bg-gray-100 text-gray d-flex align-items-center gap-3 placeholder-glow"
                        >
                          <span className="placeholder col-6"></span>
                        </li>
                      );
                    })
                  : filteredExperts.map((expert, index) => {
                      return (
                        <li
                          key={index}
                          className="px-3 py-3 bg-gray-100 text-green d-flex align-items-center gap-3"
                        >
                          <input
                            type="checkbox"
                            className="w-auto"
                            id={`expert_checkbox_${expert._id}`}
                            checked={!!checkedExperts[expert._id]} // Checked state
                            value={expert._id}
                            onChange={(e) =>
                              expertCheckHandler(expert._id, e.target.checked)
                            }
                          />
                          <div className="profile d-flex align-items-center gap-2">
                            <div
                              className="flex-shrink-0 rounded-circle overflow-hidden"
                              style={{ width: "1.5rem", height: "1.5rem" }}
                            >
                              <img
                                alt=""
                                src={
                                  expert.userimage === ""
                                    ? "/images/default-user.png"
                                    : url.SERVER_URL + expert.userimage
                                }
                                className="img-fluid w-full h-full object-cover object-center"
                              />
                            </div>
                            <p className="name text-gray-600 fw-semibold">
                              {expert.username}
                            </p>
                          </div>
                          <a
                            href={`${url.FRONTEND_BASE_URL}/peoples/details/${expert._id}`}
                            className="text-primary d-flex align-items-center gap-1 ms-auto"
                            target="_blank"
                          >
                            <span className="d-block">Details</span>
                            <span className="d-block material-symbols-outlined">
                              chevron_right
                            </span>
                          </a>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>

          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveFaqHandler}
              disabled={isSaving}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
            >
              {t("Save Faq")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignExpertPopup;
