/* eslint-disable */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import JoditEditor from "jodit-react";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  deleteData,
  getData,
  postData,
  putData,
  uploadMultipleFile,
} from "utils/Gateway";

const SaveFaqPopup = ({
  selectedFaqId = null,
  setSelectedFaqId = () => {},
  afterPopupClose = () => {},
}) => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { t, i18n } = useTranslation();

  const spinCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [title, settitle] = useState("");
  const [details, setdetails] = useState("");
  // initial state for uploaded multiple files
  const [media, setMedia] = useState([]);
  //initial state for uploaded image id
  const [fileIds, setFileIds] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const [isAsking, setIsAsking] = useState(false);

  const [isSaving, setisSaving] = useState(false);

  const [validation, setValidation] = useState({
    titleError: false,
  });

  const editor = useRef(null);

  const detailsEditorCustomConfig = {
    readonly: false,
    placeholder: "",
    height: "315",
    uploader: {
      insertImageAsBase64URI: false,
    },
    events: {
      afterPaste: async (event) => {
        // take the saved clipboard data
        const clipboardData = event.clipboardData || window.clipboardData;
        const items = clipboardData.items;

        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf("image") !== -1) {
            const file = items[i].getAsFile();

            // Rename the file using a timestamp
            const timestamp = Date.now();
            const fileExtension = file.type.split("/")[1]; // Get the file extension (e.g., png, jpeg)
            const newFileName = `image-${timestamp}.${fileExtension}`;

            const renamedFile = new File([file], newFileName, {
              type: file.type,
            });

            try {
              // upload the file data in server
              const requestURL =
                url.API_BASE_URL +
                url.API_SINGLE_FILE_UPLOAD +
                `?token=${token}`;

              const response = await uploadSingleFile(requestURL, renamedFile);

              if (response.status) {
                const imageUrl = url.SERVER_URL + response.data.path;
                // console.log("imageUrl", imageUrl);

                // Replace blob URL with the server URL
                const editorInstance = editor.current;

                if (editorInstance) {
                  const content = editorInstance.value;
                  const regex = /<img[^>]+src="data:image\/[^">]+"/g;
                  const newContent = content.replace(regex, (match) => {
                    const base64DataMatch = match.match(/src="([^"]+)"/);
                    if (
                      base64DataMatch &&
                      base64DataMatch[1].startsWith("data:image/")
                    ) {
                      return match.replace(base64DataMatch[1], imageUrl);
                    }
                    return match;
                  });

                  // add in editor content
                  editorInstance.value = newContent;

                  // add in state content
                  setdetails(newContent);
                }
              }
            } catch (error) {
              console.error("Image upload failed:", error);
            }
          }
        }
      },
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };

  const detailsEditorConfig = useMemo(() => detailsEditorCustomConfig, []);

  //validation function
  const validationHandler = () => {
    let isValid = true;

    if (title === "") {
      setValidation((prevState) => ({ ...prevState, titleError: true }));
      isValid = false;
    }

    return isValid;
  };

  // function for multiple file upload
  const multipleFileUploadHandler = async (e) => {
    const targetFiles = e.target.files; // store file information

    let filesLength = targetFiles.length;

    let proceed = false;

    if (filesLength > 0) {
      for (let i = 0; i < filesLength; i++) {
        let f = targetFiles[i];

        if (f.size <= 12500000) {
          proceed = true;
        } else {
          proceed = false;
        }
      }

      if (proceed) {
        try {
          setIsUploading(true);
          let requestURL =
            url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

          const response = await uploadMultipleFile(requestURL, targetFiles);

          console.log(response);

          setIsUploading(false);

          resetFile();

          if (response.status) {
            const uploadedFileValues = response.data.map(
              ({ name, path, _id, ...other }) => ({
                name,
                path: url.SERVER_URL + path,
                _id,
              })
            );

            const fileIds = response.data.map(({ _id, ...other }) => ({
              _id,
            }));

            //check old file exist or not if exist then new file will concat with old file
            if (media.length === 0) {
              setMedia(uploadedFileValues);
              setFileIds(fileIds);
            } else {
              setMedia((prevMedia) => [...prevMedia, ...uploadedFileValues]);
              setFileIds((prevFileids) => [...prevFileids, ...fileIds]);
            }
          }
        } catch (error) {
          console.error("Image upload failed:", error);
        }
      }
    } else {
      return;
    }
  };

  // function for clear file value
  const resetFile = () => {
    const file = document.getElementById("faqFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  const deletFileHanlder = (index, id) => {
    const updatedIds = [...fileIds];
    const updatedmedia = [...media];

    const fileMediaindex = updatedIds.findIndex((item) => item._id === id);
    const fileIdIndex = updatedmedia.findIndex((item) => item._id === id);

    updatedIds.splice(fileIdIndex, 1);
    updatedmedia.splice(fileMediaindex, 1);

    setFileIds(updatedIds);
    setMedia(updatedmedia);
  };

  //get faq details
  const getFaqDetails = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_FAQ_DETAILS +
        `/${selectedFaqId}?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        const data = response.data;
        settitle(data.question);
        setdetails(data.answer);

        setFileIds(data.uploadids);
        setMedia(
          data.uploadedfiles.map(({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get response from ai
  const getResponseFromAI = async () => {
    try {
      setIsAsking(true);

      let apiData = {
        message: title,
        token: "lobees",
      };

      let requestURL =
        url.API_BASE_URL + url.API_GET_RESPONSE_FROM_AI + `?token=${token}`;

      const response = await postData(requestURL, apiData);

      console.log(response);

      setIsAsking(false);

      if (response.status) {
        setdetails(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //save faq function
  const saveFaqHandler = async (e) => {
    if (validationHandler()) {
      try {
        setisSaving(true);

        let apiData = {
          question: title,
          answer: details,
          answeredby: userInfo._id,
          uploads: fileIds,
        };

        let requestURL = url.API_BASE_URL;

        let response = {};

        if (selectedFaqId) {
          requestURL += url.API_UPDATE_FAQ + `/${selectedFaqId}?token=${token}`;

          response = await putData(requestURL, apiData);
        } else {
          requestURL += url.API_CREATE_FAQ + `?token=${token}`;

          response = await postData(requestURL, apiData);
        }

        setisSaving(false);

        console.log(response);

        if (response.status) {
          // close the modal
          let offCanvasPopup = document.querySelector("#saveFaqOffcanvas");
          let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
          offCanvas.hide();

          resetHandler();
          afterPopupClose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //reset function
  const resetHandler = () => {
    settitle("");
    setdetails("");
    setMedia([]);
    setFileIds([]);
    setSelectedFaqId(null);
  };

  useEffect(() => {
    if (selectedFaqId) {
      getFaqDetails();
    }
  }, [selectedFaqId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="saveFaqOffcanvas"
      aria-labelledby="saveFaqOffcanvasLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="saveFaqOffcanvasLabel">
          {t("Respond to FAQ")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="field-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Question")}
              </label>
              <input
                type="text"
                id="question"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Question")}
                value={title}
                onChange={(e) => {
                  settitle(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    titleError: false,
                  }));
                }}
              />
              {validation.titleError && (
                <div className="faqquestionError text-danger mt-1">
                  Please fill out this field
                </div>
              )}
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Answer")}
              </label>
              <JoditEditor
                ref={editor}
                value={details}
                config={detailsEditorConfig}
                onBlur={(newContent) => {
                  // console.log("New content in on blur----->", newContent);
                  setdetails(newContent);
                }}
                onChange={(newContent, event) => {
                  // setdetails(newContent);
                }}
              />
              <div className="d-flex justify-content-end mt-2">
                <button
                  className="btn btn-info rounded-5 shadow-none d-flex align-items-center gap-2 btn-info"
                  onClick={getResponseFromAI}
                  disabled={isAsking ? true : false}
                  style={{
                    cursor: isAsking ? "not-allowed" : "pointer",
                  }}
                >
                  {isAsking && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}

                  <span className="suggest-ai-text">Suggest by AI</span>
                </button>
              </div>
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t(" Attach File")}{" "}
                <i>
                  Max 20 MB /
                  (jpg,jpeg,png,gif,doc,docx,pdf,xlsx,xls,mp4,webm,ogg )
                </i>
              </label>
              <input
                type="file"
                id="faqFile"
                multiple
                className="form-control"
                onChange={multipleFileUploadHandler}
              />
              {isUploading && (
                <div className="d-flex align-items-center bg-primary mt-2">
                  <h6 className="text-white fw-bold">{t("Uploading Files")}</h6>
                  {spinCount.map((item) => {
                    return (
                      <div
                        key={item}
                        className="spinner-grow mx-1 text-white"
                        style={{ height: "6px", width: "6px" }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    );
                  })}
                </div>
              )}

              <ul className="d-flex flex-wrap gap-2 text-center faq-upload-files">
                {media.map((file, index) => {
                  const fileExtension = file.name.split(".").pop();

                  return (
                    <li
                      key={index}
                      className="file d-flex flex-column align-items-center justify-content-center p-3 position-relative mw-350"
                    >
                      {fileExtension === "jpg" ||
                      fileExtension === "png" ||
                      fileExtension === "jpeg" ||
                      fileExtension === "gif" ||
                      fileExtension === "png" ? (
                        <>
                          <Link
                            to="#"
                            className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                            onClick={() => deletFileHanlder(index, file._id)}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          <img
                            src={file.path}
                            className="img-fluid mb-2 thumb-img"
                            width={150}
                            alt="17365...jpg"
                          />
                          <p className="name mb-1">{file.name}</p>
                        </>
                      ) : null}
                      {fileExtension === "doc" || fileExtension === "txt" ? (
                        <>
                          <Link
                            to="#"
                            className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                            onClick={() => deletFileHanlder(index)}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          <img
                            src="/assets/img/doc.svg"
                            className="img-fluid mb-2"
                            alt="doc"
                          />
                          <p className="name mb-1">{file.name}</p>
                        </>
                      ) : null}

                      {fileExtension === "pdf" ? (
                        <>
                          <Link
                            to="#"
                            className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                            onClick={() => deletFileHanlder(index)}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          <img
                            src="/assets/img/pdf.svg"
                            className="img-fluid mb-2"
                            alt="17365...pdf"
                          />
                          <p className="name mb-1">{file.name}</p>
                        </>
                      ) : null}
                      {fileExtension === "xlsx" ? (
                        <>
                          <Link
                            to="#"
                            className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                            onClick={() => deletFileHanlder(index)}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          <img
                            src="/assets/img/xls.svg"
                            className="img-fluid mb-2"
                            alt="xlsx"
                          />
                          <p className="name mb-1">{file.name}</p>
                        </>
                      ) : null}
                      {fileExtension === "mp4" ? (
                        <>
                          {" "}
                          <Link
                            to="#"
                            className="delete position-absolute top-0 end-0 mt-2 me-2 removeattachment"
                            onClick={() => deletFileHanlder(index)}
                          >
                            <span className="d-block material-symbols-outlined">
                              delete
                            </span>
                          </Link>
                          <video
                            controls
                            className="img-fluid mb-2"
                            width={200}
                          >
                            <source src={file.path} type="video/mp4" />
                          </video>
                          <p className="name mb-1">{file.name}</p>{" "}
                        </>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveFaqHandler}
              disabled={isSaving}
              style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
            >
              {t("Save Faq")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveFaqPopup;
